<template>
  <Suspense>
    <component :is="Renderer" v-bind="$attrs" />
  </Suspense>
</template>
<script lang="ts" setup>
import { capitalise } from '@avvoka/shared'
import { computed, defineAsyncComponent } from 'vue'
import ASpinner from './ASpinner.vue'

const props = defineProps<{
  name?: string
  darkName?: string
  lightName?: string
}>()

const isDarkMode = computed(() => false) // TODO: Implement dark mode detection
const iconComponentName = computed(() => {
  if(props.name) {
    return capitalise(props.name) + 'Icon'
  }
  if(isDarkMode.value && props.darkName != null) {
    return props.darkName
  }
  if(!isDarkMode.value && props.lightName != null) {
    return props.lightName
  }
  console.warn('No icon name provided!')
  return 'NotSet'
})

const icons = import.meta.glob(['../../assets/icons/*.vue', '../../assets/icons/Solid/*.vue', '../../assets/icons/Outline/*.vue'])

const Renderer = computed(() => {
  return defineAsyncComponent({
    loader: () => {
      if(iconComponentName.value === 'NotSet' || icons[`../../assets/icons/${iconComponentName.value}.vue`] == null) {
        return import('../../assets/icons/NotSet.vue')
      }
      return icons[`../../assets/icons/${iconComponentName.value}.vue`]() as Promise<{ default: any }>
    },
    loadingComponent: ASpinner,
    suspensible: true,
  })
})
</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ASVG',
  inheritAttrs: false
})
</script>
